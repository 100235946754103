<template>
    <div>
        <header-portrate v-if="!is_mobile" />
        <exports-print ref="exportbar" v-if="!is_mobile" />
        <div v-if="is_mobile" style="padding:5px;background:#d7b100">
            <div class="text-center p-2" style="background:red;color:#fff;margin:auto 50px;" @click="$router.push({path: '/logout'})">
                {{ lang.logout }}
            </div>
        </div>
        <div>
            <TabsComp :tabs="tabs" v-if="!is_mobile" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
                <v-row class="mt-10">
                    <v-col
                        cols="12"
                        md="10"
                        sm="12">
                        <form autocomplete="off">
                            <v-row>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.start_date}}</label>
                                    <b-input-group>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.end_date}}</label>
                                    <b-input-group>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList"/>
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.mobile}}</label>
                                    <b-input-group>
                                        <b-form-input class="inborder" v-model="mobile" style="background:#c3d9ff;" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                    <label>{{lang.industrial}}</label>
                                    <b-input-group>
                                        <b-form-select class="selborder" v-model="industrial" :options="InDustr" style="" />
                                    </b-input-group>
                                </v-col>
                                <v-col cols="12" md="2" sm="12">
                                <label></label>
                                <b-input-group style="margin-top:-5px">
                                    <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getClients()">{{lang.search}}</b-button>
                                </b-input-group>
                                </v-col>
                            </v-row>
                        </form>
                    </v-col>
                    <v-col cols="12" md="2" sm="12" class="text-end pt-7">
                        <b-button
                            v-b-toggle.add_CrmCase
                            class="btn-sm btn "
                            @click="resetAllValues()"
                            style="margin-top:9px;width:165px;background:transparent;border:none !important;margin-left:-7px !important;"
                            id="multiply-button" 
                            >
                            <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_customer}}</div>
                        </b-button>
                    </v-col>
                    <v-col cols="12" :style="`direction: `+lang.dir+`;`">
                        <div  v-if="is_mobile">
                            <v-card v-for="(item,index) in tablerows" :style="`margin:5px;border:1px solid #000`" :key="index">
                                <v-card-title style="font-size:18px;color:#000 !important;">{{ item.customer_name }}</v-card-title>
                                <v-card-subtitle style="color:#000 !important;">{{ item.last_update }}</v-card-subtitle>
                                <v-card-text :style="`background:${item.next_action_bg} !important;color:${item.next_action_co} !important`">
                                    <div style="color:#000 !important;">{{lang.next_action}} : {{ item[`next_action_`+lang.langname] }}</div>
                                    <div style="color:#000 !important;">{{lang.next_action_date}} : {{ item.next_action_date }}</div>
                                </v-card-text>
                                <v-avatar rounded="0" :style="`width:100px;position:absolute;top:10px; left: 10px;background:${item.status_bg} !important;color:${item.status_co} !important`">
                                    {{ item[`status_`+lang.langname] }}
                                </v-avatar>
                                <v-card-actions class="text-center">
                                    <v-btn style="margin:auto;width:100px;background:blue;color:#fff !important" @click="openView(item.id)" v-b-toggle.view_crm>{{ lang.view }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </div>
                        <v-data-table
                            :headers="headers"
                            :items="tablerows"
                            :search="search"
                            :items-per-page="15"
                            width="100%"
                            id="MainTable"
                            hide-default-footer
                            fixed-header
                            v-if="!is_mobile"
                        >
                            <template v-slot:item="row">
                                <tr>
                                    <td style="width:60px">{{ row.item.counter }}</td>
                                    <td style="width:60px">{{ row.item[`work_type_`+lang.langname] }}</td>
                                    <td nowrap class="text-center" style="width:80px;">{{ row.item.last_update }}</td>
                                    <td nowrap style="width:80px;">{{ row.item.mobile }}</td>
                                    <td nowrap>{{ row.item.customer_name }}</td>
                                    <td style="width:80px;" >{{ row.item.city }}</td>
                                    <td style="width:80px;" :style="`background:${row.item.industrial_bg} !important;color:${row.item.industrial_co} !important`">{{ row.item[`industrial_`+lang.langname] }}</td>
                                    <!-- <td :style="`width:80px;background:${row.item.last_action_bg} !important;color:${row.item.last_action_co} !important;`" >{{ row.item[`last_action_`+lang.langname] }}</td> -->
                                    <td :style="`width:80px;background:${row.item.next_action_bg} !important;color:${row.item.next_action_co} !important;`" >{{ row.item[`next_action_`+lang.langname] }}</td>
                                    <td nowrap class="text-center" style="width:80px;">{{ row.item.next_action_date }}</td>
                                    <td :style="`width:80px;background:${row.item.status_bg} !important;color:${row.item.status_co} !important;`" >{{ row.item[`status_`+lang.langname] }}</td>
                                    <td nowrap>{{ row.item.agent }}</td>
                                    <td style="margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" @click="openView(row.item.id)" v-b-toggle.view_crm>{{lang.view}}</div>
                                    </td>
                                </tr>
                            </template>
                            <template slot="body.append">
                                    <!-- <tr class="black--text tfoot" v-if="$store.state.licenseType.showCardNums">
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                        <td class="backBlack" style="font-size:1.2em;text-align:center;">{{lang.totals}}</td>
                                        <td class="backBlack" style="font-size:1.2em;text-align:center;">{{ sumField('parts') }}</td>
                                        <td class="backGreen" style="font-size:1.2em;text-align:center;">{{ sumField('ftotal') }}</td>
                                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('vat') }}</td>
                                        <td class="backGreen" style="font-size:1.2em;text-align:center;">{{ sumField('paid') }}</td>
                                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('remaining') }}</td>
                                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('expen') }}</td>
                                        <td class="backEmpty"></td>
                                        <td class="backEmpty"></td>
                                    </tr> -->
                            </template>
                        </v-data-table>
                        
                    </v-col>
                    <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">
                        <v-container>
                            <div class="text-center pt-2">
                            <v-pagination
                                v-model="page"
                                value="10"
                                :length="pageCount"
                                color="#000"
                                @input="getNext(page)"
                            ></v-pagination>
                            </div>
                        </v-container>
                    </v-col>
                </v-row>
            </v-row>
        </div>
        <crmView ref="crmView" />
        <addCrmCustomer ref="crmAdd" />
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import ExportsPrint from '@/components/exports-print.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
import crmView from '@/components/crm/crmView.vue'
import addCrmCustomer from '@/components/crm/addCrmCustomer.vue'
import axios from 'axios'


export default{
    name: 'NewCRM',
    components: {
        addCrmCustomer,
        HeaderPortrate,
        Footer,
        crmView,
        ExportsPrint
        ,TabsComp
    },
    data() {
        return {
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear() - 1,
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            tablerows: [
                // {
                //     crm_id: 1,
                //     work_type: 1,
                //     last_update: '2023-07-01',
                //     mobile: '0566566160',
                //     customer_name: 'محمد المعراوي',
                //     city: 'الرياض',
                //     last_action: 1,
                //     next_action: 2,
                //     next_action_date: '2023-07-10',
                //     status: 1,
                //     agent: 'محمد',
                // },
                // {
                //     crm_id: 2,
                //     work_type: 4,
                //     last_update: '2023-06-29',
                //     mobile: '0595907777',
                //     customer_name: 'منصور القحطاني',
                //     city: 'الرياض',
                //     last_action: 3,
                //     next_action: 4,
                //     next_action_date: '2023-07-12',
                //     status: 2,
                //     agent: 'منصور',
                // },
                // {
                //     crm_id: 3,
                //     work_type: 3,
                //     last_update: '2023-06-29',
                //     mobile: '0595907777',
                //     customer_name: 'منصور القحطاني',
                //     city: 'الرياض',
                //     last_action: 3,
                //     next_action: 4,
                //     next_action_date: '2023-07-12',
                //     status: 3,
                //     agent: 'منصور',
                // },
                // {
                //     crm_id: 4,
                //     work_type: 2,
                //     last_update: '2023-06-29',
                //     mobile: '0595907777',
                //     customer_name: 'منصور القحطاني',
                //     city: 'الرياض',
                //     last_action: 3,
                //     next_action: 4,
                //     next_action_date: '2023-07-12',
                //     status: 4,
                //     agent: 'منصور',
                // },
            ],
            search: '',
            page: 1,
            pageCount:0,
            staticOption: [],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            mobile: '',
            industrial: 1,
            cpage:0,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        is_mobile: function(){
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        headers: function(){
            let t = [
                {
                    text: "#",
                    align: 'center',
                    filterable: false,
                    value: 'cardid',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.work_type,
                    align: 'center',
                    filterable: false,
                    value: 'work_type',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.last_update,
                    align: 'center',
                    filterable: false,
                    value: 'last_update',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.mobile,
                    align: 'center',
                    filterable: false,
                    value: 'mobile',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.customer_name,
                    align: 'center',
                    filterable: false,
                    value: 'full_name',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.city,
                    align: 'center',
                    filterable: false,
                    value: 'city',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.industrial,
                    align: 'center',
                    filterable: false,
                    value: 'industrial',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                // {
                //     text: this.lang.last_action,
                //     align: 'center',
                //     filterable: false,
                //     value: 'last_action',
                //     sortable: false,
                //     class: 'pa-0 backBlack'
                // },
                {
                    text: this.lang.next_action,
                    align: 'center',
                    filterable: false,
                    value: 'next_action',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.next_action_date,
                    align: 'center',
                    filterable: false,
                    value: 'next_action_date',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.status,
                    align: 'center',
                    filterable: false,
                    value: 'status',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.agent,
                    align: 'center',
                    filterable: false,
                    value: 'agent',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                    value: 'agent',
                    sortable: false,
                    class: 'pa-0 backBlack'
                },

            ];
            return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        InDustr: function(){
            let t = [{text: '', value: 0}];
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'inDustrial'){
                    t.push({text: this.staticOption[i][`name_`+this.lang.langname],value: this.staticOption[i].value})
                }
            }
            return t;
        },
        Statics: function(){
            let t = {
                crmActions: {},
                crmStatus: {},
                crmType: {},
            };
            for(let i = 0;i < this.staticOption.length;i++){
                if(this.staticOption[i].option_phrase == 'crmActions'){
                    t.crmActions[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
                else if(this.staticOption[i].option_phrase == 'crmStatus'){
                    t.crmStatus[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
                else if(this.staticOption[i].option_phrase == 'crmType'){
                    t.crmType[this.staticOption[i].value] = {
                        id: this.staticOption[i].value,
                        name_ar: this.staticOption[i].name_ar,
                        name_en: this.staticOption[i].name_en,
                        bgColor: this.staticOption[i].bgColor,
                        color: this.staticOption[i].color,
                    }
                }
            }

            return t;
        },
        tablesRow: function(){
            
            return this.tablerows
        }
    },
    methods: {
        openView(id){
            this.$refs.crmView.getCRMData (id);
        },
        getall(type,id,ret){
            // return this.Statics[type][id][ret];
        },
        getNext(page){
            this.cpage = parseInt(page) - 1;
            this.getClients();
        },
        getClients(){
            let post = new FormData();
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            post.append('type','getCrmClient')
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            // post.append('data[sdate]',this.sdate)
            // post.append('data[edate]',this.edate)
            post.append('data[sdata]',this.sdate);
            post.append('data[edata]',this.edate);
            post.append('data[mobile]',this.mobile);
            post.append('data[cpage]',this.cpage);
            post.append('data[industrial]',this.industrial);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                this.tablerows = response.data.results.data;
                this.pageCount = parseInt(parseInt(response.data.results.allres) / 15) + 1
                this.page = parseInt(this.cpage) + 1
            })
        },
        resetAllValues(){
            // console.log("related");
        },
        getStaticOp(){
            
            let post = new FormData();
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            post.append('type','getStaticOptions')
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[test]','1')
            axios.post(this.$store.state.SAMCOTEC.r_path, post)
            .then((response) => {
                if(response && response.data){
                    this.staticOption = response.data.results.data;
                    this.users = response.data.results.users;
                    // this.getClients()
                }
            })

        }
    },
    created(){
        this.getStaticOp()
        this.getClients()
    }
}
</script>