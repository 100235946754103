<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="add_crmnote" aria-labelledby="sidebar-no-header-title" no-header backdrop width="700px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.add_notes}}</span>
                        </div>
                        <div @click="hide" id="hideNotes" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12" md="12" sm="12">
                         <label>{{ lang.note_type }}</label>
                        <b-input-group>
                        <b-form-select class="selborder"
                            v-model="note_type"
                            :options="note_types"
                        ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="note_type == 2">
                         <label>{{ lang.whats_type }}</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="whats_type"
                                :options="whats_types"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                            </b-input-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <label>{{lang.notes}}</label>
                        <b-form-textarea class="inborder"
                            v-model="notes"
                            value=""
                            required
                            ></b-form-textarea>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" v-if="whats_type != 1">
                        <label>{{lang.whats_url_multi}}</label>
                        <b-form-input class="inborder"
                            v-model="whats_link"
                            value=""
                            ></b-form-input>
                    </v-col>
                </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addNote()" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            notes: '',
            crm_id: 0,
            note_type: 1,
            whats_type: 1,
            whats_link: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        note_types: function(){
            let t = [];
            const types = [
                {text_ar: 'ملاحظة', text_en: 'Note', value: 1},
                {text_ar: 'رسالة واتس', text_en: 'WhatsApp', value: 2},
            ]
            for(let i=0;i<types.length;i++){
                let text = types[i][`text_`+this.lang.langname];
                t.push({text: types[i][`text_`+this.lang.langname], value: types[i].value})
            }
            return t;
        },
        whats_types: function(){
            let t = [];
            const types = [
                {text_ar: 'رسالة نصية', text_en: 'Text Message', value: 1},
                {text_ar: 'ارسال صورة مع نص', text_en: 'Image with text', value: 2},
                {text_ar: 'ارسال ملف', text_en: 'Documents', value: 3},
                {text_ar: 'ارسال فيديو', text_en: 'Vedio', value: 4},
            ]
            for(let i=0;i<types.length;i++){
                let text = types[i][`text_`+this.lang.langname];
                t.push({text: types[i][`text_`+this.lang.langname], value: types[i].value})
            }
            return t;
        }
    },
    methods: {
        addNote(){
            if(this.notes == ''){
                let message = 'يجب ان تقوم باضافة الملاحظة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }

            if(this.note_type == 2 && this.whats_type != 1 && this.whats_link == ''){
                let message = 'يجب عليك ان تقوم باضافة رابط الصورة - الملف - الفيديو ';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }

            const post = new FormData();
            post.append("type" , "addNewNoteToCRM");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crm_id]",this.crm_id);
            post.append("data[notes]",this.notes);
            post.append("data[note_type]",this.note_type);
            post.append("data[whats_type]",this.whats_type);
            post.append("data[whats_link]",this.whats_link);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                let message = 'تمت الاضافة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getCRMData(this.crm_id);
                document.getElementById('hideNotes').click();
            });
            
            this.message = '';
        }
    },
    created() {
        //this.getNotes();
    },
}
</script>